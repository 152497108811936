import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import VKPost from "../components/VKPost";
import { Link } from "gatsby";

const Tech = () => (
  <>
    <VKPost
      ownerId={265870743}
      postId={696270}
      hash={"897x7sHjALxtKAc-F_ehmMNI-w"}
    >
      <Link to="https://vk.com">
        <StaticImage
          className="hidden sm:block"
          loading="lazy"
          src="../images/fallback/vk.2021.02.20.png"
        />
        <StaticImage
          className="hidden ipSE:block"
          loading="lazy"
          src="../images/fallback/vk.2021.02.20.png"
        />
      </Link>
    </VKPost>
    <Link to="https://vk.com">
      <StaticImage
        className="hidden sm:block max-w-[550px]"
        loading="lazy"
        src="../images/fallback/vk.2021.02.20.png"
      />
      <StaticImage
        className="hidden ipX:block ipMAX:hidden max-w-[550px]"
        loading="lazy"
        src="../images/fallback/vk.2021.02.20.png"
      />
    </Link>
  </>
);

export default Tech;
